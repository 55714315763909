<template>
  <div class="entry-recurrence-picker">
    <!-- {{ rrule }} -->
    <span class="mr-2">Repeat every </span>
    <v-text-field
      v-model="interval"
      type="number"
      class="entry-recurrence-picker__interval-input d-inline-block mr-2"
      solo
      dense
    />
    <v-select
      v-model="frequency"
      :items="frequencies"
      class="entry-recurrence-picker__frequency-select d-inline-block"
      solo
      dense
    />

    <template v-if="frequency === RRule.WEEKLY">
      <!-- TODO i18n -->
      <div class="mb-2">
        Repeat on
      </div>
      <v-btn-toggle v-model="selectedWeekdays" multiple mandatory class="mb-6">
        <v-btn
          v-for="(w, i) in weekdays"
          :key="i"
          class="px-2"
          small
          min-width="auto"
          >{{ w.toString().replace(' ', '') }}</v-btn
        >
      </v-btn-toggle>
    </template>
    <v-checkbox
      v-else-if="frequency === RRule.MONTHLY && isLastDay"
      class="mb-4"
      v-model="lastMonthDay"
      label="On the last day of the month"
    />

    <!-- <div class="mb-2">Ends</div>
    <v-radio-group
      v-model="endOption"
      class="entry-recurrence-picker__end-options pt-0"
    >
      <v-radio v-for="option in endOptions" :key="option" :value="option">
        <template v-slot:label>
          {{ option }}

          <v-menu v-if="option === 'on'">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                solo
                readonly
                dense
                hide-details
                class="d-inline-block ml-2"
                :value="untilDisplay"
              />
            </template>

            <v-date-picker v-model="until" />
          </v-menu>
          <template v-else-if="option === 'after'">
            <v-text-field
              type="number"
              solo
              dense
              hide-details
              class="entry-recurrence-picker__end-option-after d-inline-block mx-2"
              v-model="count"
            />

            occurrences
          </template>
        </template>
      </v-radio>
    </v-radio-group> -->

    <div>
      <v-btn color="primary" @click="$emit('recurrencePicked', rrule)">{{
        $t('app.save')
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import { getDTfromDateObject } from '@/helpers/convert-dates'
import { RRule } from 'rrule'

export default {
  props: {
    dateObject: {
      type: Object,
      required: true,
    },
    rruleString: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      interval: 1,
      frequency: RRule.WEEKLY,
      selectedWeekdays: [this.$DateTime.local().weekday - 1],
      weekdays: [
        RRule.MO,
        RRule.TU,
        RRule.WE,
        RRule.TH,
        RRule.FR,
        RRule.SA,
        RRule.SU,
      ],
      lastMonthDay: false,
      endOption: 'never',
      endOptions: ['never', 'on', 'after'],
      until: this.$DateTime.local().toISODate(),
      count: 12,

      RRule,
    }
  },

  computed: {
    dt() {
      const { dateScheduled } = getDTfromDateObject(this.dateObject)
      return dateScheduled
    },

    isLastDay() {
      return this.dt.daysInMonth === this.dt.day
    },

    untilDisplay() {
      return this.$DateTime
        .fromISO(this.until, { zone: 'utc' })
        .toLocaleString()
    },

    frequencies() {
      return [
        {
          text: this.$t('app.day'),
          value: RRule.DAILY,
        },
        {
          text: this.$t('app.week'),
          value: RRule.WEEKLY,
        },
        {
          text: this.$t('app.month'),
          value: RRule.MONTHLY,
        },
        {
          text: this.$t('app.year'),
          value: RRule.YEARLY,
        },
      ]
    },

    rrule() {
      const dtstart = this.dt.setZone('utc', { keepLocalTime: true }).toJSDate()

      const ruleOptions = {
        freq: this.frequency,
        interval: this.interval,
        dtstart,
      }

      if (this.frequency === RRule.WEEKLY) {
        ruleOptions.byweekday = this.selectedWeekdays
      } else if (this.frequency === RRule.MONTHLY && this.lastMonthDay) {
        ruleOptions.bymonthday = -1
      }

      // if (this.endOption === 'on') {
      //   ruleOptions.until = new Date(this.until)
      // } else if (this.endOption === 'after') {
      //   ruleOptions.count = this.count
      // } else {
      //   ruleOptions.count = 365
      // }

      return new RRule(ruleOptions)
    },
  },

  mounted() {
    this.initData(this.rruleString)
  },

  methods: {
    initData(rruleString) {
      if (!rruleString) return

      const { freq, interval, byweekday, bymonthday } = RRule.parseString(
        rruleString
      )

      if (freq) this.frequency = freq
      if (interval) this.interval = interval
      if (byweekday) this.selectedWeekdays = byweekday.map(w => w.weekday)
      if (bymonthday === -1) this.lastMonthDay = true
    },
  },
}
</script>

<style lang="scss">
.entry-recurrence-picker {
  &__interval-input {
    width: 4rem;
  }
  &__frequency-select {
    width: 7rem;
  }
  &__end-options {
    text-transform: capitalize;

    & .v-label {
      flex: none !important;
    }
  }
  &__end-option-after {
    width: 4.5rem;
  }
}
</style>
