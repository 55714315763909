<template>
  <div>
    <v-autocomplete
      :label="$t('components.entry-tags-picker.select-or-create-tags')"
      v-model="tags"
      :items="activeTags"
      item-text="id"
      item-value="id"
      :search-input.sync="tagSearchInput"
      multiple
      hide-details
      @input="$emit('tagsSelected', tags)"
    >
      <template v-slot:prepend-item>
        <div class="px-4 py-2" v-if="validTag">
          <i18n path="components.entry-tags-picker.add-0">
            <v-btn
              color="primary"
              x-small
              class="no-transform"
              @click="createAndAddTag(validTag)"
              >{{ validTag }}</v-btn
            >
          </i18n>
        </div>
        <div v-else-if="!tagSearchInput" class="text-caption grey--text px-2">
          {{ $t('components.entry-tags-picker.start-typing') }}
        </div>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip small :color="minimal ? '' : item.color" class="black--text">{{
          item.id
        }}</v-chip>
      </template>
      <template v-slot:item="data">
        <div :style="{ color: minimal ? '' : data.item.color }">
          {{ data.item.id }}
        </div>
      </template>
      <template v-slot:no-data><span></span></template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  props: {
    selectedTags: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      tagSearchInput: '',
      tags: [],
    }
  },

  mounted() {
    this.tags = this.selectedTags || []
  },

  computed: {
    ...mapState('app', ['minimal']),
    ...mapGetters('tags', ['activeTags']),

    validTag() {
      let tag = this.tagSearchInput

      if (!tag) return

      if (tag[0] !== '#') tag = '#' + tag

      return /^#{1}[^\s]{1,25}$/.test(tag) && tag
    },
  },

  methods: {
    ...mapActions('tags', ['createTag']),

    async createAndAddTag(tag) {
      await this.createTag(tag)

      this.tagSearchInput = ''

      this.tags.push(tag)

      this.$emit('tagsSelected', this.tags)
    },
  },
}
</script>
