<template>
  <v-autocomplete
    :label="$t('components.entry-list-picker.select-a-list')"
    :value="selectedList"
    :items="activeLists"
    item-text="title"
    item-value="id"
    :return-object="false"
    :search-input.sync="listSearchInput"
    auto-select-first
    hide-details
    @change="$emit('listSelected', $event)"
  >
    <template v-slot:prepend-item>
      <div v-if="listSearchInput && !listExists" class="px-4 py-2">
        <i18n path="components.entry-list-picker.add">
          <v-btn
            color="primary"
            x-small
            class="no-transform"
            @click="createAndAddList(listSearchInput)"
          >
            {{ listSearchInput }}
          </v-btn>
        </i18n>
      </div>
      <div v-else-if="!listSearchInput" class="text-caption grey--text px-2">
        {{ $t('components.entry-list-picker.start-typing') }}
      </div>
    </template>
    <template v-slot:no-data><span></span></template>
    <template v-slot:item="data">
      <div :style="{ color: data.item.color }">
        {{ data.item.title }}
      </div>
    </template>
    <template v-if="selectedList" v-slot:append-outer>
      <v-icon @click="$emit('listSelected', null)">fas fa-times</v-icon>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    selectedList: {
      required: true,
    },
  },

  data() {
    return {
      listSearchInput: '',
    }
  },

  computed: {
    ...mapGetters('lists', ['activeLists']),

    listExists() {
      const listTitles = this.activeLists.map(l => l.title)
      return listTitles.includes(this.listSearchInput)
    },
  },

  methods: {
    ...mapActions('lists', ['createList']),

    async createAndAddList(e) {
      const listDoc = await this.createList(e)

      this.listSearchInput = ''

      this.$emit('listSelected', listDoc.id)
    },
  },
}
</script>
