<template>
  <div class="entry-type-picker d-flex justify-space-between text-center">
    <div
      v-for="(entryType, i) in entryTypes"
      :key="i"
      class="entry-type-picker__tile px-3 py-1 clickable"
      :class="{ active: selectedType === entryType.key }"
      @click="$emit('typeClicked', entryType.key)"
    >
      <v-icon small>{{ entryType.icon }}</v-icon>
      <div class="text-caption">
        {{ entryType.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    selectedType: {
      required: true,
      type: String,
    },
  },

  computed: {
    ...mapState('entries', ['entryTypesMap']),

    entryTypes() {
      return Object.keys(this.entryTypesMap).map(key => {
        return {
          key,
          ...this.entryTypesMap[key],
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.entry-type-picker {
  &__tile {
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
    }
    &.active {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
