<template>
  <v-card class="entry-images-picker pa-4">
    <v-form ref="form">
      <v-file-input
        v-model="files"
        accept="image/*"
        :label="$t('components.entry-images-picker.select-images')"
        prepend-icon="fa fa-file-image"
        multiple
        solo
        counter
        :rules="rules.numImages"
      ></v-file-input>

      <v-row class="entry-images-picker__image-box">
        <v-col v-for="(image, i) in images" :key="i" sm="12" md="6">
          <v-card outlined>
            <v-img v-if="image.src" :src="image.src" max-height="200">
              <div class="pa-2 d-flex justify-end text">
                <!-- <v-btn
                  icon
                  small
                  @click="image.src = rotateBase64Image90deg(image.src)"
                >
                  <v-icon small>fa fa-undo</v-icon>
                </v-btn> -->

                <v-btn icon small color="primary" @click="files.splice(i, 1)">
                  <v-icon small>fa fa-times</v-icon>
                </v-btn>
              </div>
            </v-img>
          </v-card>
        </v-col>
      </v-row>

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          :disabled="!images.length"
          @click="uploadEntryImages"
          >{{ $t('components.entry-images-picker.upload') }}</v-btn
        >
      </v-card-actions>
    </v-form>

    <v-overlay :value="uploading" absolute color="white" opacity="0.9">
      <div class="text-h6 black--text mb-2 px-8">
        {{ $t('components.entry-images-picker.uploading') }}
      </div>
      <v-progress-linear
        class="entry-images-picker__progress"
        indeterminate
        color="primary"
        rounded
      ></v-progress-linear>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { numImages } from '@/util/vuetify-rules'

export default {
  props: {
    entryId: {
      required: true,
      type: String,
    },
    existingImages: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      files: [],
      images: [],
      uploading: false,
    }
  },

  computed: {
    rules() {
      return {
        numImages,
      }
    },
  },

  watch: {
    files: function(files) {
      this.images = []

      files
        .filter(file => file.type.startsWith('image/'))
        .map(file => {
          let image = {
            src: null,
            file,
          }

          this.images.push(image)

          if (FileReader) {
            const fr = new FileReader()

            fr.onload = e => {
              image.src = e.target.result
            }

            fr.onerror = e => {
              alert(e.message)
            }

            fr.readAsDataURL(file)
          }
        })
    },
  },

  methods: {
    ...mapActions('entries', ['uploadImages']),

    async uploadEntryImages() {
      if (this.$refs.form.validate()) {
        this.uploading = true

        try {
          await this.uploadImages({
            images: this.images,
            entryId: this.entryId,
          })

          this.$emit('imagesUploaded')
        } catch (error) {
          alert(error.message)
        } finally {
          this.uploading = false
        }
      }
    },
  },
}
</script>
