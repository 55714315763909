<template>
  <div class="bullet-date-picker">
    <v-btn-toggle v-model="level" class="d-flex justify-space-between" group>
      <v-btn class="flex-grow-1 ma-0" :value="$scopeMap.day">
        {{ $t('components.bullet-date-picker.day') }}
      </v-btn>
      <v-btn class="flex-grow-1 ma-0" :value="$scopeMap.week">
        {{ $t('components.bullet-date-picker.week') }}
      </v-btn>
      <v-btn class="flex-grow-1 ma-0" :value="$scopeMap.month">
        {{ $t('components.bullet-date-picker.month') }}
      </v-btn>
      <v-btn class="flex-grow-1 ma-0" :value="$scopeMap.year">
        {{ $t('components.bullet-date-picker.year') }}
      </v-btn>
    </v-btn-toggle>

    <v-date-picker
      ref="picker"
      first-day-of-week="1"
      :type="pickerType"
      :value="isoDate"
      @change="
        $emit('dateSelected', getDateObject($DateTime.fromISO($event), level))
      "
      @click:year="changeYear"
      :allowed-dates="allowedDates"
      color="primary"
      no-title
      full-width
      tile
      class="elevation-0"
    ></v-date-picker>

    <v-menu
      v-model="recurrenceMenu"
      :close-on-content-click="false"
      offset-x
      left
      top
      :disabled="!isPremium || disableRepeater"
    >
      <template v-slot:activator="{ on: menu }">
        <v-tooltip top :disabled="!disableRepeater">
          <template v-slot:activator="{ on: tooltip }">
            <div v-on="{ ...tooltip, ...menu }">
              <v-btn
                block
                tile
                class="text-caption"
                :disabled="disableRepeater"
                @click="openPremiumDialog"
              >
                <v-icon small class="mr-2">fas fa-sync</v-icon>
                <span class="rrule-text text-ellipsis">{{
                  rruleText || 'Set repeat'
                }}</span>
                <template v-if="rruleText">
                  <v-spacer />
                  <v-icon
                    color="error"
                    x-small
                    @click.stop="$emit('removeRepeater')"
                    >fas fa-trash-alt</v-icon
                  >
                </template>
              </v-btn>
            </div>
          </template>
          <span>
            <template v-if="type !== 'task'">
              {{ $t('components.bullet-date-picker.must-be-task') }}
            </template>
            <template v-else-if="level !== $scopeMap.day">
              {{
                $t('components.bullet-date-picker.can-only-set-on-day-entries')
              }}
            </template>
            <template v-else-if="!dateObject">
              {{ $t('components.bullet-date-picker.set-date-first') }}
            </template>
          </span>
        </v-tooltip>
      </template>

      <v-card class="pa-4">
        <EntryRecurrencePicker
          :dateObject="dateObject"
          :rruleString="rruleString"
          @recurrencePicked="recurrencePicked"
        />
      </v-card>
    </v-menu>

    <v-btn
      block
      tile
      color="error--text"
      class="text-caption"
      :disabled="!dateObject"
      @click="$emit('dateSelected', null)"
      >{{ $t('components.bullet-date-picker.remove-date') }}</v-btn
    >
  </div>
</template>

<script>
import { getDateObject, getDTfromDateObject } from '@/helpers/convert-dates'

import EntryRecurrencePicker from '@/components/EntryRecurrencePicker'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    EntryRecurrencePicker,
  },

  props: {
    dateObject: {
      required: true,
      validator: function(val) {
        return val === null || typeof val === 'object'
      },
    },
    type: {
      type: String,
      required: true,
    },
    rruleString: {
      type: String,
    },
    rruleText: {
      type: String,
    },
  },

  data() {
    return {
      pickerType: 'date',
      level: null,
      recurrenceMenu: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['isPremium']),

    selectedDate() {
      const { dateScheduled } = getDTfromDateObject(this.dateObject)
      return dateScheduled
    },

    dateLevel() {
      const { dateUnit } = getDTfromDateObject(this.dateObject)

      return dateUnit
    },

    isoDate() {
      return (
        (this.level === this.dateLevel &&
          this.selectedDate &&
          this.selectedDate.toFormat(
            `y-LL${this.level === this.$scopeMap.month ? '' : '-dd'}`
          )) ||
        ''
      )
    },

    disableRepeater() {
      return (
        this.level !== this.$scopeMap.day ||
        !this.dateObject ||
        this.type !== 'task'
      )
    },
  },

  watch: {
    level: function(newVal) {
      this.setPickerType(newVal)
    },
  },

  created() {
    this.level = this.dateLevel != null ? this.dateLevel : this.$scopeMap.day
  },

  mounted() {
    this.setPickerType(this.level)
  },

  methods: {
    ...mapActions('auth', ['openPremiumDialog']),

    allowedDates(val) {
      return this.level === this.$scopeMap.week
        ? this.$DateTime.fromISO(val).weekday === 1
        : true
    },

    recurrencePicked(e) {
      this.$emit('recurrencePicked', e)
      this.recurrenceMenu = false
    },

    getDateObject,

    async setPickerType(level) {
      if (level === this.$scopeMap.month) {
        this.pickerType = 'month'
      } else if (level === this.$scopeMap.year) {
        this.pickerType = 'date'
        await this.$nextTick()

        this.$refs.picker.activePicker = 'YEAR'
      } else {
        this.pickerType = 'date'
        await this.$nextTick()

        this.$refs.picker.activePicker = 'DATE'
      }
    },

    changeYear(year) {
      this.$emit(
        'dateSelected',
        getDateObject(this.$DateTime.fromISO(year), this.$scopeMap.year)
      )

      this.$refs.picker.activePicker = 'YEAR'
    },
  },
}
</script>

<style lang="scss" scoped>
.bullet-date-picker {
  display: block;
}
.rrule-text {
  max-width: 200px;
}
</style>
