<template>
  <v-dialog
    :value="entry"
    @click:outside="SET_SHOW_ENTRY(null)"
    width="700"
    origin="top center"
  >
    <v-card v-if="entry" class="entry-form js-entry-form" :key="entryDialogId">
      <v-container>
        <v-row>
          <v-col cols="12" md="9">
            <i18n
              v-if="parent"
              path="components.entry-form.subtask-of"
              tag="div"
              class="text-caption mb-2"
            >
              <a class="font-weight-bold" @click="SET_SHOW_ENTRY(parent.id)">
                {{ parent.title }}
              </a>
            </i18n>

            <v-textarea
              ref="title"
              v-model="title"
              :placeholder="$t('components.entry-form.title')"
              rows="1"
              auto-grow
              autofocus
              class="text-h5 font-weight-bold borderless-input pt-0"
              hide-details
              @keypress.enter.prevent="$refs.content.focus()"
              @change="updateTitle"
            ></v-textarea>

            <v-textarea
              ref="content"
              :placeholder="$t('components.entry-form.write-here')"
              v-model="content"
              auto-grow
              rows="1"
              class="borderless-input pt-0"
              @change="updateContent"
            ></v-textarea>

            <template v-if="entry.type === 'task'">
              <h5>{{ $t('components.entry-form.subtasks') }}</h5>
              <EntryList
                :entries="subtasks"
                class="js-subtasks mb-2"
                context="subtasks"
                @addNewEntry="addSubtask"
              />
            </template>

            <template v-if="images.length">
              <h5>{{ $t('components.entry-form.images') }}</h5>

              <v-row class="d-flex" dense>
                <v-col v-for="(image, i) in images" :key="i" cols="12" md="4">
                  <EntryImage
                    :image="image"
                    @deleteBtnClicked="deleteEntryImage(image.id)"
                  />
                </v-col>
              </v-row>
            </template>
          </v-col>

          <v-col cols="12" md="3">
            <v-menu offset-y min-width="auto">
              <template v-slot:activator="{ on }">
                <div v-on="on" class="clickable mb-2">
                  <v-icon class="mr-2" small>{{
                    entryTypesMap[entry.type].icon
                  }}</v-icon>
                  {{ entryTypesMap[entry.type].text }}
                </div>
              </template>

              <v-card>
                <EntryTypePicker
                  :selectedType="entry.type"
                  @typeClicked="updateType($event)"
                />
              </v-card>
            </v-menu>

            <v-menu
              v-model="entryDatePicker"
              max-width="290"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  class="clickable mb-2"
                  :class="{ 'grey--text': !dateString }"
                >
                  <div>
                    <v-icon class="mr-2" small>fas fa-calendar-day</v-icon>
                    {{ dateString || $t('components.entry-form.add-date') }}
                  </div>
                  <div
                    v-if="
                      repeater &&
                        repeater.text &&
                        entry.type === 'task' &&
                        entry.dateScheduled
                    "
                    class="text-caption"
                  >
                    <v-icon x-small class="mr-1">fas fa-sync</v-icon>
                    {{ repeater.text }}
                  </div>
                </div>
              </template>
              <v-card v-if="entryDatePicker">
                <BulletDatePicker
                  :dateObject="dateObject"
                  :type="entry.type"
                  :rruleText="repeater && repeater.text"
                  :rruleString="repeater && repeater.rrule"
                  @dateSelected="dateSelected($event)"
                  @recurrencePicked="
                    createRepeater({ rrule: $event, taskId: entry.id })
                  "
                  @removeRepeater="removeRepeater"
                />
              </v-card>
            </v-menu>

            <v-menu
              v-model="listPickerMenu"
              offset-y
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on }">
                <div v-on="on" class="clickable mb-2">
                  <v-icon
                    class="mr-2"
                    small
                    :color="minimal || !list ? '' : list.color"
                    >fas fa-list</v-icon
                  >
                  <span
                    v-if="list"
                    :style="{ color: minimal ? '' : list.color }"
                  >
                    {{ list.title }}
                  </span>
                  <span class="grey--text" v-else>{{
                    $t('components.entry-form.add-to-list')
                  }}</span>
                </div>
              </template>
              <v-card class="pa-2">
                <EntryListPicker
                  :selectedList="entry.list"
                  @listSelected="listSelected($event)"
                />
              </v-card>
            </v-menu>

            <v-menu
              offset-y
              :close-on-content-click="false"
              max-width="290"
              :disabled="!isPremium"
            >
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  class="clickable text-caption mb-2"
                  @click="openPremiumDialog"
                >
                  <template v-if="entryTags.length">
                    <span
                      v-for="tag in entryTags"
                      :key="tag.id"
                      :style="{ color: minimal ? '' : tag.color }"
                    >
                      {{ tag.id }}
                    </span>
                  </template>
                  <div v-else class="grey--text">
                    {{ $t('components.entry-form.add-tags') }}
                  </div>
                </div>
              </template>
              <v-card class="pa-2">
                <EntryTagsPicker
                  :selectedTags="entry.tags || []"
                  @tagsSelected="tagsSelected($event)"
                />
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

        <div class="d-flex justify-space-between">
          <v-dialog
            v-model="imagesPickerDialog"
            width="500"
            height="500"
            :disabled="!isPremium"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="on" @click="openPremiumDialog">
                <v-icon small>fas fa-images</v-icon>
              </v-btn>
            </template>

            <EntryImagesPicker
              v-if="imagesPickerDialog"
              :entryId="entry.id"
              :existingImages="images"
              @imagesUploaded="imagesUploaded"
            />
          </v-dialog>
          <v-btn icon small @click="archive">
            <v-icon small color="error">fas fa-trash-alt</v-icon>
          </v-btn>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { getDTfromDateObject, dtToString } from '@/helpers/convert-dates'

import EntryList from '@/components/EntryList'
import BulletDatePicker from '@/components/BulletDatePicker'
import EntryTypePicker from '@/components/EntryTypePicker'
import EntryListPicker from '@/components/EntryListPicker'
import EntryTagsPicker from '@/components/EntryTagsPicker'
import EntryImagesPicker from '@/components/EntryImagesPicker'
import EntryImage from '@/components/EntryImage'
import { EntriesDatabase } from '@/services/database'
import { getDateObject } from '../helpers/convert-dates'

export default {
  components: {
    EntryList,
    BulletDatePicker,
    EntryTypePicker,
    EntryListPicker,
    EntryTagsPicker,
    EntryImagesPicker,
    EntryImage,
  },

  data() {
    return {
      entry: null,
      title: '',
      content: '',
      parent: null,
      subtasks: [],
      subtasksObservable$: null,
      listPickerMenu: false,
      imagesPickerDialog: false,
      uploadTasks: [],
      entryListener: null,
      entryDatePicker: false,
    }
  },

  computed: {
    ...mapGetters('lists', ['activeLists']),
    ...mapGetters('auth', ['isPremium']),
    ...mapState('tags', ['tags']),
    ...mapState('app', ['minimal']),

    ...mapState('entries', ['entryDialogId', 'entryTypesMap', 'entryImages']),
    ...mapState('repeaters', ['repeaters']),

    dtSelected() {
      return (
        this.entry.dateScheduled &&
        this.$DateTime.fromJSDate(this.entry.dateScheduled.toDate())
      )
    },

    level() {
      return this.entry.dateUnit
    },

    dateObject() {
      return getDateObject(this.dtSelected, this.level)
    },

    list() {
      return (
        this.entry.list && this.activeLists.find(l => l.id === this.entry.list)
      )
    },

    entryTags() {
      return this.tags.filter(
        t => this.entry.tags && this.entry.tags.includes(t.id)
      )
    },

    repeater() {
      const repeaterId = this.entry.repeaterId

      if (!repeaterId) return null

      return repeaterId && this.repeaters.find(r => r.id === repeaterId)
    },

    images() {
      return this.entryImages[this.entry.id] || []
    },

    dateString() {
      return dtToString(this.dtSelected, this.level)
    },
  },

  watch: {
    entryDialogId: {
      handler: function(newVal) {
        if (newVal) this.listenEntry(newVal)
      },
      immediate: true,
    },

    entry: {
      handler: function(newVal, oldVal) {
        if (newVal) {
          if (oldVal && oldVal.id === newVal.id) return

          this.initData()
        } else {
          this.listPickerMenu = false
          this.imagesPickerDialog = false
        }
      },
      immediate: true,
    },
  },

  beforeDestroy() {
    this.disposeListener()
  },

  methods: {
    ...mapMutations('entries', ['SET_SHOW_ENTRY']),
    ...mapActions('entries', [
      'updateEntry',
      'deleteEntry',
      'newEntry',
      'getImages',
      'deleteImage',
    ]),
    ...mapActions('repeaters', ['createRepeater']),
    ...mapActions('auth', ['openPremiumDialog']),

    listenEntry(entryId) {
      if (this.entryListener) this.entryListener()

      this.entryListener = EntriesDatabase.listenEntry(
        entryId,
        entry => (this.entry = entry)
      )
    },

    async initData() {
      this.title = ''
      this.content = ''
      this.parent = null
      this.subtasks = []

      this.getSubtasks(this.entry.id)
      this.getImages({ entryId: this.entry.id })
      this.title = this.entry.title
      this.content = this.entry.content

      if (this.entry.parentId) {
        this.parent = await EntriesDatabase.getEntry(this.entry.parentId)
      }
    },

    disposeListener() {
      if (this.subtasksObservable$) this.subtasksObservable$.unsubscribe()
    },

    getSubtasks(parentId) {
      this.disposeListener()

      this.subtasksObservable$ = EntriesDatabase.observeSubtasksEntries$(
        parentId,
        data => {
          this.subtasks = data
        }
      )
    },

    updateTitle(title) {
      this.updateEntry({
        id: this.entry.id,
        data: {
          title,
        },
      })
    },

    updateContent(content) {
      this.updateEntry({
        id: this.entry.id,
        data: {
          content,
        },
      })
    },

    async updateType(type) {
      await this.updateEntry({
        id: this.entry.id,
        data: {
          type,
        },
      })
    },

    async archive() {
      if (confirm(this.$t('components.entry-form.confirm-delete'))) {
        await this.updateEntry({
          id: this.entry.id,
          data: {
            archived: true,
          },
        })

        this.SET_SHOW_ENTRY(null)
      }
    },

    async addSubtask() {
      if (!this.isPremium) return this.openPremiumDialog()

      const newEntryDoc = await this.newEntry({
        parentId: this.entry.id,
      })

      await this.$nextTick()

      const el = document.querySelector(
        `.js-entry-form .js-day-entry[data-id="${newEntryDoc.id}"] .js-title-input`
      )

      el.focus()
    },

    dateSelected(scheduled) {
      this.updateEntry({
        id: this.entry.id,
        data: {
          ...getDTfromDateObject(scheduled, true),
        },
      })

      this.entryDatePicker = false
    },

    listSelected(list) {
      this.updateEntry({
        id: this.entry.id,
        data: {
          list,
        },
      })

      this.listPickerMenu = false
    },

    tagsSelected(tags) {
      this.updateEntry({
        id: this.entry.id,
        data: {
          tags,
        },
      })
    },

    removeRepeater() {
      this.updateEntry({
        id: this.entry.id,
        data: {
          repeaterId: null,
        },
      })
    },

    async imagesUploaded() {
      this.imagesPickerDialog = false

      this.getImages({ entryId: this.entry.id, refresh: true })
    },

    async deleteEntryImage(imageId) {
      if (confirm(this.$t('components.entry-form.confirm-delete-image'))) {
        this.deleteImage({ entryId: this.entry.id, imageId })
      }
    },
  },
}
</script>
